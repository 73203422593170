module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NSN94DM","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Open+Sans:300,400,600,700","Open Sans:300,400,600,700"]},"custom":{"families":["aglet-sans","Neo Sans W01 Black"],"urls":["https://use.typekit.net/ocj6awh.css","https://fast.fonts.net/cssapi/3cf56f85-84a5-4606-af3d-285316d355e2.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"WelBijWim","short_name":"WelBijWim","start_url":"/","background_color":"#00a7e0","theme_color":"#00a7e0","display":"standalone","icon":"src/img/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"e25091b0acb9cd641245c3d6cc23090f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
